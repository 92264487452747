.navi-lang {
	display: flex;

	& > .item {
		display: flex;
		align-items: center;
		padding: 0 6px;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 1px;
		color: var(--sec-grey);
		cursor: pointer;
		transition: all 0.3s ease;

		&.active,
		&:hover {
			color: var(--black);
		}
	}

	&.white {
		& > .item {
			color: var(--white);
			opacity: 0.6;

			&.active,
			&:hover {
				color: var(--white);
				opacity: 1;
			}
		}
	}
}
