/*
 * This is for global css usage.
 */
@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap';
@import '../config/palette.css';

@font-face {
	font-family: Mark-Pro, sans-serif;
	src: url('../src/font/Mark-Pro.ttf') format('truetype');
}

@font-face {
	font-family: RedHatDisplay;
	src: url('../src/font/RedHatDisplay-Medium.ttf') format('truetype');
}

*,
*::after,
*::before {
	box-sizing: border-box;
	font-family: RedHatDisplay, 'Noto Sans JP', 'Noto Sans TC', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	outline: none;
}

body,
input,
select,
textarea,
button {
	-webkit-font-smoothing: antialiased;
	font-family: 'Noto Sans TC', sans-serif;
}

body {
	margin: 0;
	overflow-x: hidden;
	overflow-y: overlay;
	width: 100%;
	color: var(--black);

	&:global(.no-scroll) {
		position: fixed;
		overflow-y: scroll;
	}
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
	margin: 0;
	padding: 0;
}
