.page-wrapper {
	position: relative;
	width: 100%;

	&.background {
		background-color: var(--background);
	}

	&.white {
		background-color: var(--white);
	}

	& main {
		width: 100%;
		margin: 0 auto;
	}

	&.with-header {
		padding-top: 70px;

		@media screen and (--phone) {
			padding-top: 60px;
		}
	}

	&.min-view-height {
		min-height: 100vh;
	}
}

.section-wrapper {
	width: 100%;
	overflow: visible;

	&.max-view-width {
		max-width: 1440px;
		margin: auto;
	}

	&.overflow-hidden {
		overflow: hidden;
	}

	& > .wrapper {
		padding: 0 80px;
		margin-right: auto;
		margin-left: auto;

		@media screen and (--phone) {
			padding: 0 16px;
		}
	}

	&.without-padding {
		& > .wrapper {
			padding: 0;
		}
	}

	&:not(.without-margin) {
		&:first-child {
			margin-top: 60px;

			@media screen and (--phone) {
				margin-top: 20px;
			}
		}

		/* &:not(:last-child) {
			margin-bottom: 140px;

			@media screen and (--phone) {
				margin-bottom: 40px;
			}
		} */
	}
}
