.search {
	& > .icon {
		height: 32px;

		@media screen and (--tablet), screen and (--phone) {
			width: 20px;
			cursor: pointer;
		}
	}

	& > .search-wrapper {
		position: fixed;
		top: 70px;
		left: 0;
		width: 100vw;
		height: 100%;
		background-color: rgba(4 4 4 / 70%);

		& > .container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 156px;
			width: 100%;
			transition: all 0.3s ease-in-out;
			will-change: transform;
			background-color: var(--white);
			padding: 40px 10px;
			border-radius: 0 0 32px 32px;

			@media screen and (--tablet), screen and (--phone) {
				height: 108px;
			}

			& > .search-input {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 76px;
				width: 100%;
				max-width: 1120px;
				background-color: var(--background);
				border: 1px solid var(--light-grey);
				border-radius: 50px;
				padding: 0 16px;

				@media screen and (--tablet), screen and (--phone) {
					height: 60px;
					padding: 0 8px 0 4px;
				}

				& > .input {
					width: 100%;

					& > input {
						background-color: rgba(0 0 0 / 0%);
					}
				}

				& > .button {
					padding: 16px 27px;
					min-width: unset;
					border-radius: 50px;

					@media screen and (--tablet), screen and (--phone) {
						padding: 12px 19px;
					}
				}
			}
		}

		&.open {
			top: 70px;

			@media screen and (--tablet), screen and (--phone) {
				top: 60px;
			}

			& > .container {
				transform: translateY(0%);
			}
		}

		&.close {
			background-color: rgba(0 0 0 / 0%);
			top: -100%;
			transition: top 0.3s ease-in-out;

			& > .container {
				transform: translateY(-250%);
			}
		}
	}
}
