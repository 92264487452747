.header {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 70px;
	background-color: var(--white);
	transition: all 0.3s ease;
	z-index: 800;

	@media screen and (--tablet), screen and (--phone) {
		height: 60px;
		flex-direction: column;

		&.open {
			height: 100%;
		}
	}

	& > .top {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		min-height: 70px;
		padding-left: 60px;

		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: ' ';
			background-color: var(--light-grey);
			height: 1px;
			width: 100%;
		}

		@media screen and (--tablet), screen and (--phone) {
			min-height: 60px;
			padding-left: 20px;
			padding-right: 20px;
			justify-content: space-between;
		}

		& .logo {
			display: flex;
			flex-shrink: 0;
			width: 58px;
			height: 46px;
			margin-right: 60px;

			@media screen and (--tablet), screen and (--phone) {
				width: 50px;
				height: 40px;
			}
		}

		& .bar {
			width: 1px;
			height: 20px;
			background-color: var(--grey);
			margin: 0 16px;

			@media screen and (--tablet), screen and (--phone) {
				display: none;
			}
		}

		& .navi-lang {
			@media screen and (--tablet), screen and (--phone) {
				display: none;
			}
		}

		& .sponsor {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			gap: 8px;
			padding: 10px 12px;
			min-width: 153px;
			height: 100%;
			background-color: var(--secondary);
			color: var(--white);
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			margin-left: 34px;
			transition: all 0.3s ease;
			flex-shrink: 0;

			& span {
				white-space: nowrap;
			}

			&:hover {
				background-color: var(--secondary-hover);
			}

			@media screen and (--tablet) {
				display: none;
			}
		}
	}

	& > .bottom {
		height: 100%;
		padding-bottom: 100px;
		overflow: overlay;
		background-color: var(--background);
		z-index: 5;

		& > .wrapper {
			margin: 20px 16px;
		}

		& > .function-group {
			display: flex;
			width: 100%;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
			overflow: hidden;
			height: 76px;

			& > .sponsor {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				padding: 12px 16px;
				color: var(--white);
				white-space: nowrap;
				width: max-content;
				background-color: var(--secondary);
				font-size: 14px;
				min-width: max-content;
			}

			& > .lang {
				display: flex;
				justify-content: flex-end;
				width: 100%;
				background-color: var(--primary);
				color: var(--white);
				gap: 6px;

				& > .navi-lang {
					margin-right: 22px;
				}
			}

			&.fixed {
				position: fixed;
				bottom: 0;
			}
		}
	}
}
