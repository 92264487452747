.footer {
	display: flex;
	position: relative;
	width: 100%;
	color: white;
	background-color: var(--secondary);
	padding: 100px 90px 40px;
	min-height: 286px;

	@media screen and (--phone) {
		flex-direction: column;
		padding: 62px 40px 80px;
	}

	& > .wrapper {
		display: flex;
		gap: 90px;
		position: relative;
		width: 100%;

		@media screen and (--phone) {
			flex-direction: column;
			gap: 40px;
		}

		& > .back-to-top {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			font-size: 14px;
			font-weight: 500;
			line-height: 19px;
			letter-spacing: 0.8px;
			right: 0;
			bottom: 0;

			@media screen and (--phone) {
				bottom: unset;
				right: -16px;
			}

			& > .arrow {
				width: 48px;
				height: 48px;
				margin-bottom: 8px;
			}
		}

		& > .logo {
			& > .icon {
				width: 72px;
				height: 54px;

				@media screen and (--phone) {
					width: 90px;
					height: 72px;
				}
			}
		}

		& > .footer-content {
			display: flex;
			flex-direction: column;

			& > .top {
				display: flex;
				gap: 120px;

				@media screen and (--phone) {
					flex-direction: column;
					gap: 4px;
				}

				& > .footer-block {
					display: flex;
					flex-direction: column;

					& > .footer-item {
						display: flex;
						flex-direction: column;
						gap: 10px;
						margin-bottom: 30px;
						font-size: 14px;
						font-weight: 500;
						letter-spacing: 0.8px;

						@media screen and (--phone) {
							margin-bottom: 28px;
						}

						& > .footer-sub-grid {
							display: grid;
							grid-template-columns: 60px 1fr;
							gap: 8px 0;

							& > .label {
								width: max-content;
								margin-right: 12px;
							}
						}

						& > .footer-list {
							display: flex;
							flex-wrap: wrap;
							gap: 18px;
							row-gap: 10px;

							& > .footer-item-link {
								display: flex;
								align-items: center;
								position: relative;
								color: var(--background);
								transition: all 0.3s ease;

								&.with-bar {
									padding-left: 16px;

									&::before {
										position: absolute;
										content: ' ';
										background-color: var(--background);
										width: 6px;
										height: 1px;
										left: 0;
										top: 50%;
									}
								}

								&.with-icon {
									gap: 10px;

									& > .icon svg path {
										fill: white;
									}
								}

								&:hover {
									opacity: 0.7;
								}
							}

							&.follow-us {
								@media screen and (--phone) {
									display: grid;
									grid-template-columns: max-content max-content max-content;
								}
							}
						}
					}

					&.reverse {
						@media screen and (--phone) {
							flex-direction: column-reverse;
						}
					}
				}
			}

			& > .copy-right {
				font-weight: 400;
				font-size: 14px;
				letter-spacing: 0.8px;
				margin-top: 36px;

				@media screen and (--phone) {
					margin-top: 0;
				}
			}
		}
	}
}
