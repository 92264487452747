/* stylelint-disable no-descending-specificity */
.nav-container {
	display: flex;
	margin-right: auto;
	overflow-x: hidden;
	position: relative;

	& .arrow-icon {
		display: flex;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 32px;
		z-index: 2;
		width: 83px;
		justify-content: flex-start;
		flex-shrink: 0;
		background:
			linear-gradient(
				-90deg,
				rgb(255 255 255 / 0%) 0.52%,
				rgb(255 255 255 / 30%) 14%,
				rgb(255 255 255 / 70%) 27%,
				#fff 60%,
				#fff 100%
			);

		& svg {
			z-index: 2;

			path {
				fill: var(--secondary);
			}
		}

		&.right-arrow {
			left: unset;
			right: 0;
			justify-content: flex-end;
			background:
				linear-gradient(
					90deg,
					rgb(255 255 255 / 0%) 0.52%,
					rgb(255 255 255 / 30%) 14%,
					rgb(255 255 255 / 70%) 27%,
					#fff 60%,
					#fff 100%
				);
		}
	}

	& .navigator {
		display: flex;
		margin-right: auto;
		overflow-x: hidden;
		position: relative;

		@media screen and (--phone) {
			display: none;
		}

		& > ul {
			display: flex;
			gap: 24px;
			list-style-type: none;
			padding: 0;
			align-items: baseline;

			& > li {
				& .group-title,
				& span,
				& a {
					position: relative;
					font-size: 16px;
					line-height: 24px;
					font-weight: 500;
					color: var(--black);
					padding: 18px 0;
					transition: all 0.3s ease;
					white-space: nowrap;

					&:hover {
						color: var(--secondary);
					}
				}
			}
		}

		& .group {
			position: relative;

			& > .group-title {
				cursor: pointer;

				& > a {
					color: inherit;
				}
			}

			& > .group-wrapper {
				display: none;
				position: fixed;
				z-index: 900;
				padding-top: 12px;
				background: rgb(0 0 0 / 0%);
				transform: translate(-25%, 60px);
				opacity: 0;
				transition: opacity 0.3s ease;

				& > .group-content {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-width: 160px;
					width: max-content;
					box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
					border-radius: 12px;
					background-color: var(--white);
					padding: 0 16px 16px;

					& > .inner-item {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;

						& > a {
							display: flex;
							justify-content: space-between;
							color: var(--black);
							width: 100%;
							text-align: center;
							font-weight: 500;
							line-height: 24px;
							padding-top: 16px;
							padding-bottom: 8px;
							border-bottom: 1px solid var(--background);
							gap: 8px;
							transition: all 0.3s ease;

							& svg path {
								fill: var(--white);
								transition: all 0.3s ease;
							}
						}

						&:last-of-type {
							& > a {
								padding-bottom: 0;
								border-bottom: none;
							}
						}

						&.active {
							& > a {
								color: var(--secondary);

								& svg path {
									fill: var(--secondary);
								}
							}
						}

						&:hover {
							cursor: pointer;

							& > a {
								color: var(--secondary);

								& svg path {
									fill: var(--secondary);
								}
							}
						}
					}
				}
			}

			&:hover {
				& > .group-wrapper {
					display: inline-block;
					opacity: 1;
				}
			}
		}
	}
	/* stylelint-enable no-descending-specificity */
}
