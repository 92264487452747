/* stylelint-disable no-descending-specificity */
.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	cursor: pointer;
	border-radius: 4px;
	border-style: solid;
	border-color: transparent;
	border-width: 1px;
	white-space: nowrap;
	transition: all 0.3s ease;

	&.has-icon {
		justify-content: space-between;
	}

	/* size */
	&.large {
		height: 50px;
		min-width: 160px;
		padding: 12px 16px;
	}

	&.medium {
		height: 40px;
		min-width: 140px;
		padding: 8px 12px;
	}

	&.small {
		height: 32px;
		min-width: 120px;
		padding: 4px 12px;
	}

	/* shape */
	&.pill {
		height: fit-content;
		width: fit-content;
		min-width: 0;
		padding: 8px 16px;
		border-radius: 50px;
	}

	& > span {
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.8px;
		transition: all 0.3s ease;
	}

	& > .icon {
		& svg path {
			transition: all 0.3s ease;
		}
	}

	&.disabled {
		cursor: not-allowed;
	}

	&.primary.filled {
		background-color: var(--primary);

		& > span {
			color: var(--white);
		}

		& > .icon svg path {
			fill: var(--white);
		}

		&.disabled {
			background-color: var(--primary-disable);
		}

		&:not(.disabled):hover {
			background-color: var(--primary-hover);
		}

		&:not(.disabled):active {
			background-color: var(--primary-dark);
		}
	}

	&.primary.outline {
		border-color: var(--primary);
		background-color: transparent;

		& > span {
			color: var(--primary);
		}

		& > .icon svg path {
			fill: var(--primary); /* var(--white); */
		}

		&.disabled {
			border-color: var(--primary-disable);

			& > span {
				color: var(--primary-disable);
			}

			& > .icon svg path {
				fill: var(--primary-disable);
			}
		}

		/* In mobile button, click means hovering. It would make the hover style unchanged as clicking one time */
		&:not(.disabled):hover {
			background-color: var(--primary);

			& > span {
				color: var(--white);
			}

			& > .icon svg path {
				fill: var(--white);
			}
		}

		&:not(.disabled):active {
			background-color: var(--primary-hover);

			& > span {
				color: var(--white);
			}

			& > .icon svg path {
				fill: var(--white);
			}
		}
	}

	&.secondary.filled {
		background-color: var(--secondary);

		& > span {
			color: var(--white);
		}

		& > .icon svg path {
			fill: var(--white);
		}

		&.disabled {
			background-color: var(--secondary-disable);
		}

		&:not(.disabled):hover {
			background-color: var(--secondary-hover);
		}

		&:not(.disabled):active {
			background-color: var(--secondary-dark);
		}
	}

	&.secondary.outline {
		border-color: var(--secondary);
		background-color: transparent;

		& > span {
			color: var(--secondary);
		}

		& > .icon svg path {
			fill: var(--secondary); /* var(--white); */
		}

		&.disabled {
			border-color: var(--sec-grey);
			background-color: var(--light-grey);

			& > span {
				color: var(--dark-grey);
			}

			& > .icon svg path {
				fill: var(--dark-grey);
			}
		}

		/* In mobile button, click means hovering. It would make the hover style unchanged as clicking one time */
		&:not(.disabled):hover {
			background-color: var(--secondary-light);

			& > span {
				color: var(--secondary);
			}

			& > .icon svg path {
				fill: var(--secondary);
			}
		}

		&:not(.disabled):active {
			background-color: var(--secondary-light);

			& > span {
				color: var(--secondary);
			}

			& > .icon svg path {
				fill: var(--secondary);
			}
		}
	}

	&.grey.filled {
		background-color: var(--light-grey);
		color: var(--dark-grey);

		&.disabled {
			border-color: var(--sec-grey);

			& > span {
				color: var(--dark-grey);
			}

			& > .icon svg path {
				fill: var(--dark-grey);
			}
		}
	}

	&.grey.outline {
		border-color: var(--sec-grey);
		color: var(--dark-grey);
	}
}

/* stylelint-enable no-descending-specificity */
