.icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;

	&.large {
		width: 32px;
	}

	&.normal {
		width: 24px;
	}

	&.small {
		width: 20px;
	}

	&.x-small {
		width: 16px;
	}

	&.xx-small {
		width: 12px;
	}

	&.clickable {
		cursor: pointer;
	}

	& > svg {
		width: 100%;
		height: auto;
	}
}
