.navi-function-button-group {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	gap: 24px;
	margin: auto 16px auto 31px;

	& .icon {
		height: 24px;

		&.shop {
			display: flex;
			/* stylelint-disable-next-line declaration-no-important */
			width: 28px !important;
		}
	}

	& .to-list {
		display: flex;
		align-items: center;
		position: relative;
		width: 28px;

		& > .shop-number {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: -6px;
			top: -3px;
			width: 18px;
			height: 18px;
			background-color: var(--secondary);
			border-radius: 50%;
			border: 1px solid var(--white);
			font-size: 10px;
			line-height: 12px;
			color: var(--white);
		}
	}

	@media screen and (--tablet), screen and (--phone) {
		gap: 16px;
		margin: auto 20px auto auto;

		& .icon {
			width: 20px;
			height: 20px;
			cursor: pointer;

			&.shop {
				/* stylelint-disable-next-line declaration-no-important */
				width: 24px !important;
			}
		}
	}

	& > .user-button {
		display: flex;
		position: relative;

		& > .icon.login {
			/* stylelint-disable-next-line declaration-no-important */
			width: 32px !important;

			@media screen and (--tablet), screen and (--phone) {
				/* stylelint-disable-next-line declaration-no-important */
				width: 28px !important;
			}
		}

		& > .user-menu-wrapper {
			display: none;
			position: absolute;
			z-index: 900;
			top: 100%;
			left: 50%;
			padding-top: 12px;
			background: rgb(0 0 0 / 0%);
			transform: translateX(-50%);
			opacity: 0;
			transition: opacity 0.3s ease;

			& > .user-menu-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				min-width: 160px;
				width: max-content;
				box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
				border-radius: 12px;
				background-color: var(--white);
				padding: 0 16px 8px;

				& > .inner-item {
					display: flex;
					align-items: center;
					width: 100%;

					& > a,
					& > button {
						display: flex;
						justify-content: space-between;
						color: var(--black);
						width: 100%;
						font-family: 'Noto Sans TC', sans-serif;
						text-align: center;
						font-weight: 500;
						line-height: 24px;
						padding: 16px 0 8px;
						gap: 8px;
						transition: all 0.3s ease;

						& svg path {
							fill: var(--white);
							transition: all 0.3s ease;
						}
					}

					& > a {
						border-bottom: 1px solid var(--background);
					}

					& > button {
						border: none;
						background: transparent;
						cursor: pointer;
					}

					&:last-of-type {
						& > a {
							padding-bottom: 0;
							border-bottom: none;
						}
					}

					&.active {
						& > a {
							color: var(--secondary);

							& svg path {
								fill: var(--secondary);
							}
						}
					}

					&:hover {
						cursor: pointer;

						& > a {
							color: var(--secondary);

							& svg path {
								fill: var(--secondary);
							}
						}
					}
				}
			}
		}

		@media screen and (--desktop) {
			&:hover {
				& > .user-menu-wrapper {
					display: inline-block;
					opacity: 1;
				}
			}
		}
	}

	& .bar {
		display: none;

		@media screen and (--tablet), screen and (--phone) {
			display: flex;
			width: 1px;
			height: 20px;
			background-color: var(--grey);
			margin: 0 4px;
		}
	}

	& > .mobile-menu-wrapper {
		position: fixed;
		top: 60px;
		left: 0;
		right: 0;
		height: 0;
		background-color: var(--background);
		transition: height 0.3s ease;

		&.active {
			padding: 20px 16px;
			height: 100%;
		}

		& > .mobile-menu {
			display: flex;
			flex-direction: column;
			gap: 16px;

			& .item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;
				width: 100%;
				background-color: var(--white);
				font-weight: 500;
				font-size: 18px;
				line-height: 26px;
				letter-spacing: 0.02em;
				color: var(--black);
				padding: 12px 16px;
				border-radius: 12px;
				border: none;
			}
		}
	}
}
