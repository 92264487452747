@keyframes flash {
	0% {
		transform-origin: 100% 0;
		transform: scaleX(1);
	}

	50% {
		transform-origin: 100% 0;
		transform: scaleX(0);
	}

	50.1% {
		transform-origin: 0% 0;
		transform: scaleX(0);
	}

	100% {
		transform-origin: 0% 0;
		transform: scaleX(1);
	}
}

.link {
	display: flex;
	cursor: pointer;

	&.back-link {
		display: inline-block;
		align-items: center;
		position: relative;
		font-size: 20px;
		line-height: 1.2;
		letter-spacing: 0.8px;
		padding-bottom: 16px;
		width: fit-content;
		margin: 0 auto;
		text-transform: uppercase;
		color: var(--black);

		@media screen and (--phone) {
			font-size: 16px;
			line-height: 1.5;
		}

		& > .icon {
			width: 24px;
			height: 24px;
			margin-right: 18px;

			@media screen and (--phone) {
				width: 20px;
				height: 20px;
			}
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 13%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--black);
			transition: transform 0.3s cubic-bezier(0.13, 0.78, 0.38, 0.98);
		}

		&:hover {
			&::after {
				transform-origin: 0 0;
				transform: scaleX(1);
				animation: flash 0.6s 1;
				animation-fill-mode: forwards;
			}
		}
	}

	&.link-underlined {
		display: inline-block;
		position: relative;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		width: fit-content;
		color: var(--secondary);
		cursor: pointer;
		padding-bottom: 16px;
		letter-spacing: 0.8px;
		text-transform: uppercase;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-repeat: no-repeat;
			/* stylelint-disable-next-line declaration-colon-newline-after  */
			background-image: linear-gradient(
				to right,
				var(--secondary) 45%,
				var(--secondary-disable) 55%
			);
			background-size: 220% 100%;
			background-position: 100% 50%;
			transition: all 0.3s ease-out;
		}

		&:hover::before {
			background-position: 0% 50%;
		}
	}
}
