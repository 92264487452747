.input {
	display: flex;
	align-items: center;
	border-radius: 4px;
	overflow: hidden;

	&.has-border {
		padding: 12px 32px;
		border: 1px solid var(--grey);
		border-radius: 24px;

		&.has-button {
			padding: 12px 14px 12px 32px;
		}

		&.has-value {
			border: 1px solid var(--dark-grey);
		}
	}

	&.has-error {
		border: 1px solid var(--error);
	}

	& > .icon {
		margin-right: 8px;

		& svg path {
			fill: var(--dark);
		}

		&.has-value {
			& svg path {
				fill: var(--black);
			}
		}
	}

	& > .input {
		display: flex;
		align-items: center;
		width: 100%;
		border: none;
		padding: 16px;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 2px;
		color: var(--black);

		&.has-value {
			/* background-color: var(--primary-light); */
		}

		&.error {
			border: 1px solid var(--error);
		}

		&::placeholder {
			font-size: 14px;
			letter-spacing: 0.8px;
			color: var(--sec-grey);
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
			/* -webkit-text-fill-color: ; */
			box-shadow: 0 0 0 1000px #fafafa inset;
			transition: background-color 5000s ease-in-out 0s;
		}
	}

	& > .button {
		flex-shrink: 0;
		border-radius: 18px;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 1.2px;
		height: auto;
		margin-left: 8px;
		min-width: fit-content;

		&.with-icon {
			padding-left: 6px;
		}
	}
}
