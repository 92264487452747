.toast {
	position: fixed;
	z-index: 1000;

	& > .toast-container {
		display: flex;
		align-items: flex-start;
		position: fixed;
		left: 50%;
		padding: 11px 24px 11px 18px;
		border-radius: 4px;
		box-shadow: 0 0 20px 0 rgba(0 0 0 / 10%);
		transform: translateX(-50%);
		transition: top 0.3s ease-in-out;

		&.success {
			background-color: #6fd993;
		}

		&.warning {
			background-color: #fdb531;
		}

		&.error {
			background-color: var(--error);
		}

		& > :not(:last-child) {
			margin-right: 8px;
		}

		& > div.text {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.8px;
			color: var(--white);
			text-align: left;
			flex: 1;
			flex-shrink: 0;
			align-self: center;
			word-wrap: break-word;
			min-width: 0;
		}

		& > .icon {
			width: 24px;
		}
	}

	&.open {
		& > .toast-container {
			top: 120px;
		}
	}

	&.close {
		& > .toast-container {
			top: -200%;
		}
	}
}
