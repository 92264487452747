.accordion {
	display: flex;
	flex-direction: column;
	gap: 16px;

	& .item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		width: 100%;
		background-color: var(--white);
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		letter-spacing: 0.02em;
		color: var(--black);
		padding: 12px 16px;
		border-radius: 12px;

		& span {
			font-weight: 500;
			font-size: 18px;
			line-height: 26px;
			letter-spacing: 0.02em;
			color: var(--black);
		}

		& .icon {
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s ease;

			& svg > path {
				fill: var(--secondary);
			}

			&.minus {
				opacity: 0;
			}
		}
	}

	& > .item-group {
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		font-size: 18px;
		border-radius: 12px;
		overflow: hidden;
		max-height: 48px;
		padding-bottom: 12px;
		transition: max-height 0.3s ease;

		& .sub-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 16px;
			font-weight: 500;
			line-height: 1.5;
			letter-spacing: 0;
			padding: 12px 0;
			margin: 0 16px;
			border-bottom: 1px solid var(--background);
			color: var(--black);

			& > .arrow {
				& svg path {
					fill: var(--secondary);
				}
			}

			&:last-of-type {
				border-bottom: none;
			}
		}

		&.active {
			& .icon.minus {
				opacity: 1;
			}

			& .plus {
				opacity: 0;
			}
		}
	}
}
